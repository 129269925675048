function IconOpenInNew(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			{...props}
		>
			<path
				d="M19.5 19.5H5.5V5.5H12.5V3.5H5.5C4.39 3.5 3.5 4.4 3.5 5.5V19.5C3.5 20.6 4.39 21.5 5.5 21.5H19.5C20.6 21.5 21.5 20.6 21.5 19.5V12.5H19.5V19.5ZM14.5 3.5V5.5H18.09L8.26 15.33L9.67 16.74L19.5 6.91V10.5H21.5V3.5H14.5Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default IconOpenInNew;
