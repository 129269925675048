const SvgComponent = props => (
	<svg viewBox="0 0 32 32" {...props}>
		<path
			d="M29.3333 15.9998C29.3333 8.63984 23.36 2.6665 16 2.6665C8.63996 2.6665 2.66663 8.63984 2.66663 15.9998C2.66663 23.3598 8.63996 29.3332 16 29.3332C23.36 29.3332 29.3333 23.3598 29.3333 15.9998ZM5.33329 15.9998C5.33329 10.1065 10.1066 5.33317 16 5.33317C21.8933 5.33317 26.6666 10.1065 26.6666 15.9998C26.6666 21.8932 21.8933 26.6665 16 26.6665C10.1066 26.6665 5.33329 21.8932 5.33329 15.9998ZM21.3333 15.9998L16 21.3332L14.12 19.4532L16.2266 17.3332H10.6666V14.6665H16.2266L14.1066 12.5465L16 10.6665L21.3333 15.9998Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgComponent;
