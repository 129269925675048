const SvgComponent = props => (
	<svg viewBox="0 0 32 32" {...props}>
		<path
			d="M2.66669 15.9998C2.66669 23.3598 8.64002 29.3332 16 29.3332C23.36 29.3332 29.3334 23.3598 29.3334 15.9998C29.3334 8.63984 23.36 2.6665 16 2.6665C8.64002 2.6665 2.66669 8.63984 2.66669 15.9998ZM26.6667 15.9998C26.6667 21.8932 21.8934 26.6665 16 26.6665C10.1067 26.6665 5.33335 21.8932 5.33335 15.9998C5.33335 10.1065 10.1067 5.33317 16 5.33317C21.8934 5.33317 26.6667 10.1065 26.6667 15.9998ZM10.6667 15.9998L16 10.6665L17.88 12.5465L15.7734 14.6665H21.3334V17.3332H15.7734L17.8934 19.4532L16 21.3332L10.6667 15.9998Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgComponent;
