import PropTypes from "prop-types";
import {
	FloatingPortal,
	offset,
	shift,
	useFloating,
	useInteractions,
	arrow,
} from "@floating-ui/react";
import { useCallback, useState, useRef } from "react";
import useClickAway from "app/utils/hooks/useClickAway";
import "./FloatingButton.scss";

const FloatingButton = ({
	referenceComponent = false,
	floatingContent,
	position,
	onClick = () => {},
	onClickAway = () => {},
	popoverClassName,
}) => {
	const [open, setOpen] = useState(false);
	const floatingContentRef = useRef();
	const floatingBtnRef = useRef();
	const arrowRef = useRef();

	const {
		x,
		y,
		reference,
		floating,
		strategy,
		placement,
		middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
	} = useFloating({
		placement: position,
		strategy: "absolute",
		open,
		middleware: [offset(10), shift(), arrow({ element: arrowRef })],
	});

	const handleClickAway = event => {
		onClickAway();
		if (!floatingBtnRef.current || !floatingBtnRef.current.contains(event.target)) {
			setOpen(false);
		}
	};

	useClickAway(floatingContentRef, handleClickAway);

	const { getReferenceProps, getFloatingProps } = useInteractions([]);

	const closeFloating = useCallback(() => {
		setOpen(false);
	}, []);

	const onButtonClick = useCallback(() => {
		onClick();
		setOpen(!open);
	}, [open]);

	const staticSide = {
		top: "bottom",
		right: "left",
		bottom: "top",
		left: "right",
	}[placement.split("-")[0]];

	const arrowStyle = {
		left: arrowX !== null ? `${arrowX}px` : "",
		top: arrowY !== null ? `${arrowY}px` : "",
		right: "",
		bottom: "",
		[staticSide]: "-4px",
	};

	if (staticSide === "top") {
		arrowStyle["border-right-color"] = "white";
		arrowStyle["border-bottom-color"] = "white";
	} else if (staticSide === "right") {
		arrowStyle["border-bottom-color"] = "white";
		arrowStyle["border-left-color"] = "white";
	} else if (staticSide === "bottom") {
		arrowStyle["border-left-color"] = "white";
		arrowStyle["border-top-color"] = "white";
	} else if (staticSide === "left") {
		arrowStyle["border-top-color"] = "white";
		arrowStyle["border-right-color"] = "white";
	}

	return (
		<div className="floating-button">
			<span
				data-testid="floating-button-ref"
				ref={floatingBtnRef}
				onOuterAction={closeFloating}
				onClick={onButtonClick}
				{...getReferenceProps({ ref: reference })}
			>
				{referenceComponent}
			</span>
			<FloatingPortal>
				{open && (
					<div
						{...getFloatingProps({
							ref: floating,
							style: {
								position: strategy,
								left: x ?? "",
								top: y ?? "",
								zIndex: 4,
							},
						})}
						{...popoverClassName && { className: popoverClassName }}
					>
						<div ref={floatingContentRef} className="floating-button__content">
							{floatingContent}
						</div>
						<div className="floating-button__arrow" ref={arrowRef} style={arrowStyle} />
					</div>
				)}
			</FloatingPortal>
		</div>
	);
};

FloatingButton.propTypes = {
	position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
	referenceComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	floatingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
	onClickAway: PropTypes.func,
	popoverClassName: PropTypes.string,
};

export default FloatingButton;
