import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import ProductDestinationTitleContainer from "app/pages/.shared/ProductDestinationTitle/ProductDestinationTitleContainer";
import ProductNameWithCategory from "app/pages/.shared/ProductNameWithCategory/ProductNameWithCategory";
import { PriceType } from "app/pages/.shared/Deal/PriceType";
import { OFFER_CONTRACT_TYPES, PRODUCT_EXPERIENCES_WITH_HOTEL } from "app/constants";
import get from "lodash/get";
import { productShape, resolutionPropType } from "app/utils/propTypes";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import "./LookAlikeProduct.scss";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import Button from "app/pages/.shared/form/Button";
import { sendTagOnLookAlikeProductClick } from "app/utils/analytics";
import { NavLink } from "react-router-dom";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { containsTrainTransportationTypes } from "app/utils/utils";

const IMG_SIZES_DESKTOP = {
	large: { width: 502, height: 240 },
	xlarge: { width: 502, height: 240 },
	medium: { width: 502, height: 240 },
	small: { width: 502, height: 240 },
};

const LookAlikeProduct = ({
	product = {},
	resolution,
	position,
	showFromInfo = true,
	handleClick = () => {},
}) => {
	const handleProductClick = useCallback(() => {
		handleClick();
		sendTagOnLookAlikeProductClick({ productUri: product.uri, position: position + 1 });
	}, [product.uri]);

	const shouldOpenInNewTab = resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE;

	const travelByTrainAvailable = containsTrainTransportationTypes(product.transportationTypes);

	return (
		<NavLink
			className="lookalike-product"
			to={{ pathname: `/${product.uri}` }}
			rel="noopener noreferrer"
			target={shouldOpenInNewTab ? "_blank" : ""}
			onClick={handleProductClick}
		>
			<div className="lookalike-product__photo">
				<BackgroundProgressiveImg
					sizes={IMG_SIZES_DESKTOP}
					cloudinaryOptions={["c_fill"]}
					alt={product.country}
					src={get(product, "photos[0].url")}
					thumbnail={get(product, "photos[0].thumbnail")}
					backgroundPosition="center"
				/>
			</div>
			<div className="lookalike-product__content">
				<div className="lookalike-product__data">
					<ProductDestinationTitleContainer
						country={product.country}
						region={product.region}
						resort={product.resort}
					/>
					<ProductNameWithCategory
						categoryType={product.categoryType}
						category={product.category}
						name={product.name}
					/>
				</div>
				<div className="lookalike-product__price-type">
					<PriceType
						travelByTrainAvailable={travelByTrainAvailable}
						isRental={product.contractType === OFFER_CONTRACT_TYPES.RENTAL}
						isWithHotel={PRODUCT_EXPERIENCES_WITH_HOTEL.includes(
							product?.productExperience
						)}
						includeOfferWithFlight={product.includeOfferWithFlight}
						duration={get(product, "fromPriceType.numberOfNights")}
						publicPrice={get(product, "fromPriceType.publicPrice")}
						type={get(product, "fromPriceType.type")}
						value={get(product, "fromPriceType.value")}
						showFromInfo={showFromInfo}
					/>
				</div>
			</div>
			<div className="lookalike-product__cta">
				<Button variant="primary">
					<IconRight width={20} height={20} />
				</Button>
			</div>
		</NavLink>
	);
};

LookAlikeProduct.propTypes = {
	product: productShape,
	resolution: resolutionPropType,
	position: PropTypes.string,
	showFromInfo: PropTypes.bool,
	handleClick: PropTypes.func,
};

export default memo(LookAlikeProduct);
