import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { fromPriceTypeShape } from "app/utils/propTypes";
import "./ProductDeal.scss";
import { PriceType } from "app/pages/.shared/Deal/PriceType";

const ProductDeal = ({
	hour,
	day,
	value,
	duration,
	publicPrice,
	type,
	isRental,
	isWithHotel = true,
	includeOfferWithFlight,
	travelByTrainAvailable,
}) => {
	const isDealExpired = hour === 0 && day === 0;

	const dealNode = !isDealExpired ? (
		<div className="product-deal__price-type">
			<PriceType
				value={value}
				travelByTrainAvailable={travelByTrainAvailable}
				duration={duration}
				publicPrice={publicPrice}
				type={type}
				isRental={isRental}
				isWithHotel={isWithHotel}
				includeOfferWithFlight={includeOfferWithFlight}
			/>
		</div>
	) : (
		<div className="product-deal__status">
			<FormattedMessage id="product.deal.expired.label" />
		</div>
	);

	return <div className="product-deal">{dealNode}</div>;
};

ProductDeal.propTypes = {
	travelByTrainAvailable: PropTypes.bool,
	duration: PropTypes.number,
	type: fromPriceTypeShape,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	publicPrice: PropTypes.number,
	hour: PropTypes.number,
	day: PropTypes.number,
	isRental: PropTypes.bool,
	isWithHotel: PropTypes.bool,
	includeOfferWithFlight: PropTypes.bool,
};

export default memo(ProductDeal);
